import React, {
    createContext,
    useEffect,
    useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import authAPI from 'src/services/AuthService';
import {APP} from "../config";

const initialAuthState = {
    isAuthenticated: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        
        case 'INITIALISE': {

            console.log("INITIALISE" );

            return {
                ...state,
                isInitialised: true
            };
        }
        default: {
            return {...state};
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: 'JWT'
});

export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    //console.log("AuthProvider");

    useEffect(() => {
        const initialise = async () => {
            try {
                const result = await authAPI.checkAuth();

                console.log("result", result);

                //console.log("result", result.data, ( result.data === 'NOTAUTH' ));
                if ( result.data === 'NOTAUTH' && window.location.href.indexOf("/apps") > 1 ) {
                    //console.log("result", result.data);
                    window.location.href = '/';
                }

                if ( result.data === 'ISAUTH' && window.location.href.indexOf("/apps") == -1 ) {
                    window.location.href = '/apps';
                }

                dispatch({
                    type: 'INITIALISE',
                    payload: {}
                });

            } catch (e) {

                //TODO: to review. if i'm on /apps without auth, i go to login
                window.location.href = '/';

                console.log("result:catch", '');

                dispatch({
                    type: 'INITIALISE',
                    payload: {}
                });
            }
        };

        initialise();
    }, []);

    if (!state.isInitialised) {
        return <SplashScreen/>;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
